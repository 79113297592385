const colors = {
  primary: {
    50: "#F3FEF5",
    100: "#F3FEF5",
    200: "#E8FEED",
    300: "#DCFDE7",
    400: "#D2FBE4",
    500: "#C3F9E0",
    600: "#8ED6BC",
    700: "#62B39D",
    800: "#3E9083",
    900: "#257772",
  },
  secondary: {
    50: "#D6DBF9",
    100: "#D6DBF9",
    200: "#B0B7F3",
    300: "#818ADC",
    400: "#5A63BA",
    500: "#2C348D",
    600: "#202679",
    700: "#161B65",
    800: "#0E1151",
    900: "#080B43",
  },
};

export default colors;
